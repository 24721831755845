import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { Header } from '../atoms/Typography/Header'
import { Text } from '../atoms/Typography/Text'
import SEO from '../components/seo'
import Main from '../layouts/main'
import { Button } from '../molecules/Button/Button'
import { SectionWrapper } from '../molecules/SectionWrapper/SectionWrapper'
import { Colors } from '../styles/colors'
import { Bold } from '../styles/common'
import { Spacings } from '../styles/spacings'

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PaddedText = styled(Text)`
  margin-bottom: ${Spacings[32]};
  text-align: center;
`

const WhiteText = styled(Text)`
  color: ${Colors.background};
  padding: 0 10px;
`

const NotFoundPage = () => (
  <Main>
    <SEO title='404: Not found' />
    <SectionWrapper paddingTop paddingBottom>
      <FlexWrapper>
        <Header variant='heading1'>Błąd 404</Header>
        <PaddedText variant='xLarge'>
          Szukana strona nie została znaleziona 😞
        </PaddedText>
        <Link to='/'>
          <Button backgroundColor={Colors.pastel['blue-bright']}>
            <WhiteText font='Roboto' variant='medium'>
              <Bold>Wróć na stronę główną</Bold>
            </WhiteText>
          </Button>
        </Link>
      </FlexWrapper>
    </SectionWrapper>
  </Main>
)

export default NotFoundPage
